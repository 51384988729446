import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

const Activities = (props) => (
    <Layout>
        <Helmet>
            <title>2024 CHCSD Activities</title>
            <meta name="description" content="2024 CHCSD Activities Page" />
        </Helmet>

        <div id="main" className="alt">

            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1>2024 Activities</h1>
                        <h2>Check regularly for updates...</h2>
                    </header>

                    <p></p>
                    <p><h3>Code of Conduct</h3></p>
                    <p>The conference is designed to be a family safe and family friendly event. However, families who are attending must understand that the conduct and behavior of parents and children are representative of the South Dakota homeschool community at large. We have seen an alarming rise in disruptive and disrespectful behavior by unattended children in activities and the Vendor Hall. Please prepare your children to conduct themselves with decorum, decency and respectfulness while attending the conference and participating in activities. Children who are unable to listen and follow instructions will be removed from activities and asked to return to their parent's care. Children found running or being rambunctious in the Vendor Hall will be required to do the same.</p>
                    <p></p>

                    <div className="grid-wrapper">
                        <div className="col-6">
                            <h3>After High School Round Table</h3>
                            <p>Paralyzed by the number of after High School options? Wondering what route you or your graduate should take? This round table will feature homeschool graduates who have pursued many of these options. Come hear about their experiences and ask questions to help in your decisions. Teens and parents encouraged to attend.</p>
                        </div>
                        <div className="col-6">
                            <h3>Concert by The Sioux Falls Homeschool Choir</h3>
                            <p>The Sioux Falls Homeschool Choir is comprised of 4- to 18-year-old students who sing sacred choral music throughout the year. Besides offering homeschool kids an opportunity to perform in an ensemble, we also strive to share our music with people who may otherwise be unable to attend a concert, such as residents at senior living communities and the Union Gospel Mission. More information about the Sioux Falls Homeschool Choir can be found on the director, Elizabeth Feltes's, website (http://www.elizabethfeltes.weebly.com).</p>
                        </div>
                        <div className="col-6">
                            <h3>Cow Eye Dissection</h3>
                            <p>Nebraska Scientific has donated a Cow Eye Dissection Classroom Kit for up to 40 students. Dr. Dana Holm has graciously volunteered to facilitate this dissection. There is no age limit on this activity, but a scalpel and other sharp tools will be used. Please use your best judgement on your child's capabilities. Available seats for the activity will be filled on a first come, first served basis.</p>
                        </div>
                        <div className="col-6">
                            <h3>DSU Governors Cyber Academy - Programming Pictionary with the Edison Robots</h3>
                            <p>In this interactive session, students will blend creativity with coding by using Edison robots to illustrate pictures and ideas through programming commands. Through teamwork and imagination, they'll explore basic coding concepts while having fun creating visual representations. No prior coding experience required. Activity is intended for children 5 and up.</p>
                        </div>
                        <div className="col-6">
                            <h3>Drama Performance Workshop</h3>
                            <p>Join Brent Grosvenor and his team from Lights Up Productions for an energetic, hands-on musical theatre experience. Students will participate in learning a complete, exciting scene from Lights Up Productions' recently debuted musical, "Parables and Proverbs." No previous theatre experience is required. Each session will feature a different scene, so students may join one or both of the sessions. Ages 6-18 are welcome to attend. Consult the schedule for session times and locations.</p>
                        </div>
                        <div className="col-6">
                            <h3>Introduction to CPR/AED</h3>
                            <p>Join Jeremy Robertson, EMS educator, as he provides instruction on how to administer CPR and use an AED. Cardiopulmonary resuscitation (CPR) is a skill comprised of chest compressions, airway management and rescue breathing coordinated to save a life. Automated External Defibrillator (AED) is a portable device used to treat people suffering from sudden cardiac arrest. This session does not qualify for certification.</p>
                        </div>
                        <div className="col-6">
                            <h3>Kids Connection</h3>
                            <p>Are you looking for an activity for the kids so you can focus on the final keynote session? Let them entertain themselves with games and fellowship at the Kids Connection activity. The activity will be monitored by young adults but is not intended to be a babysitting service. If your kids are a flight risk, please keep them with you. Some games will be provided, but children are encouraged to bring a favorite game to share. Consult the schedule for activity time and location.</p>
                        </div>
                        <div className="col-6">
                            <h3>Robotics and Electronics Crash Course</h3>
                            <p>Join Brooks Jacobsen from Lake Area Technical College for a fast-paced introduction to robotics and electronics. By the end of this session, participants will have gained a foundational understanding of programming cobots, controlling robots and building basic electronic circuits, setting them up for further exploration in these exciting fields. (Ages 10 & Up)</p>
                        </div>
                        <div className="col-6">
                            <h3>Let's Paint Runaways</h3>
                            <p>Want to try your hand at painting, or maybe learn a few more skills on canvas? Back by popular demand is the painting workshop from Let's Paint Runaways. Workshops are 2.5 hours long and are offered for conference attendees aged 7-adult. The cost for each workshop is $20 and includes required materials and instruction. Participants will complete an original painting during each workshop. Workshop registration will be at the conference, near the registration table, on a first-come, first-served basis.</p>
                        </div>
                        <div className="col-6">
                            <h3>Line Dance with Lauren</h3>
                            <p>Here's a chance to work out the wiggles and pick up some new moves to showcase at your next dance opportunity. Join Lauren to learn a couple of exciting line dances that are sure to impress.</p>
                        </div>
                        <div className="col-6">
                            <h3>Praise and Worship</h3>
                            <p>Start your day with the correct focus and purpose. Join fellow homeschool families in a praise and worship session that provides the foundation to build on.</p>
                        </div>
                        <div className="col-6">
                            <h3>Rockhounding</h3>
                            <p>Have you ever wanted to hold a precious gemstone? We will take a close look at a whole array of rocks, minerals, and fossils that you can examine up close, and even hold in your own hands. What's more, you will be able to see how you can collect your own beautiful rocks and minerals — we call this "rockhounding" — and learn to identify them. We also will take a brief look at the geology of how these beauties formed. The creation is filled with so many natural wonders, and the minerals and fossils of the earth are among the most interesting of them. Take a sample of a polished gem home with you as well!</p>
                        </div>
                        <div className="col-6">
                            <h3>Self Defense (8 & Up)</h3>
                            <p>Shannon Donnell, owner of Tiger-Rock Martial Arts, specializes in training children and adults in authentic and professional techniques, as well as in developing life skills. His students enjoy a supportive, comfortable and progressive atmosphere where no one sits on the bench. Shannon strives to cultivate strength, discipline, self confidence, self control and a commitment to our community and an overall sense of honor in life. He is proud of developing strong leaders in our schools and communities and making the world a safer place. This self-defense session will teach children age-appropriate defense skills to protect themselves. This activity is for children 8 and up.</p>
                        </div>
                        <div className="col-6">
                            <h3>Small Business Owner Round Table</h3>
                            <p>A panel of local business owners will open the floor to questions from students about creating a small business, things to think about and any other questions students may have. The round table will be moderated by Chad Theisen and is targeted to children 6<sup>th</sup> grade and above, but all are welcome.</p>
                        </div>
                        <div className="col-6">
                            <h3>SD Humanities Council speaker Phyllis Schrag presents The King of Little Things</h3>
                            <p>This presentation is a storytelling, creative dramatics, audience participation session. During the 60-minutes, Phyllis Schrag, with her puppet Monique, will provide the opportunity for participants to learn the joy of literature. Literacy is a life-long goal of this SD Humanities Scholar. In the <i>King of Little Things</i> by Bil Lepp, participants learn the use of the writer's craft of alliteration. As a former educator, Phyllis employs songs such as "Supercalifragilisticexpialidocious" as well as familiar children's literature such as <i>The Duel</i> by Eugene Field. This is a very hands-on session and acting out an adaptation of <i>The Fat Cat</i>, a Swedish folktale, along with other creative dramatic opportunities will be part of the program. Target audience K-5. Older attendees could be helpers. Younger attendees are welcome if accompanied by older siblings. This presentation is sponsored by the SD Humanities Council and is free and open to the public.</p>
                        </div>
                        <div className="col-6">
                            <h3>Vocal Ensemble Foundations for Kids</h3>
                            <p>Come make a joyful noise and add your voice to an ensemble! Elizabeth will teach you some foundations of using your voice well. Breathing, dynamics, pitch, tone, rhythm and reading music will all be taught, demonstrated, and practiced during this class. Class participants are invited to perform in an optional mini concert at 5:40pm Saturday with The Sioux Falls Homeschool Choir.</p>
                        </div>
                        <div className="col-6">
                            <h3>Waterfowl 101</h3>
                            <p>Are you interested in getting started in waterfowl hunting?  This presentation will go over many aspects that are important to a beginner. Topics will include gear, decoys, setup, waterfowl ID, and calling. Presentation by Jason Nelson, the Naturalist and Outreach Coordinator for the South Dakota Game, Fish and Parks Outdoor Campus.</p>
                        </div>
                        <div className="col-6">
                            <h3>TEACHSD Leadership Coffee</h3>
                            <p>Curious who HSLDA is and what they do? Who works there and what services do they offer? Why would I join or encourage anyone else to? This session focuses on the core mission of HSLDA and why we believe it is just as needed today as it was over 40 years ago. Stay after the presentation to share ideas and opportunities with other homeschool leaders.</p>
                        </div>
                    </div>
                </div>
            </section>
            
        </div>

    </Layout>
)

export default Activities
